<template>

  <div class="prePostQuote" v-show="hasQuote()" :class="{
    preMarket: type === 'preMarket',
    postMarket: type === 'postMarket',
    priceUp: checkChangeP('priceUp'),
    priceDown: checkChangeP('priceDown')
  }">

    <i class="ri-sun-line" v-if="typeMatching('preMarket')"/>
    <i class="ri-moon-line" v-if="typeMatching('postMarket')"/>
    <span>{{quoteLabel}}</span>
  </div>
</template>

<script>

export default {
  name: "PrePostQuote",
  props: ['type', 'quotes', 'symbol'],
  data() {
    return {
      lastPrice: 0,
      quoteStatus: null
    }
  },
  mounted() {

  },
  methods: {
    hasQuote () {
      if (!this.isProUI) return false;
      return this.quotes[this.type] !== null && this.quotes[this.type] !== undefined;
    },
    checkChangeP (direction) {
      if (this.quotes[this.type]?.changeP > 0 && direction === 'priceUp') return true;
      if (this.quotes[this.type]?.changeP < 0 && direction === 'priceDown') return true;
    },
    typeMatching (type) {

      return this.type === type ||
          (
              !this.type &&
              this.quoteStatus === type
          );
    },
  },
  computed: {
    quoteLabel () {

      // console.log(this.quotes, this.type)
      if (this.quotes[this.type]) {
        const quote = this.quotes[this.type];
        if (
            [undefined, null, 0, '0'].includes(quote.price) ||
            [undefined, null, 0, '0'].includes(quote.changeP)
        ) {
          return 'N\A';
        } else {
          return `${(quote.price).toFixed(2)} (${quote.changeP}%)`
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.prePostQuote {
  margin: 0 5px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 2px 5px;
  border-radius: 4px;
  unicode-bidi: plaintext !important;
  text-align: left;
  direction: ltr;

  &.preMarket {
    background: var(--lightYellowOpacity);
    i{
      color: var(--lightYellow);
    }
    .ri-moon-line {
      display: none;
    }
  }

  &.postMarket {
    background: var(--afterMarketColorOpacity);
    i{
      color: var(--afterMarketColor);
    }
    .ri-sun-line {
      display: none;
    }
  }
}
</style>
